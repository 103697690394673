import firebase from 'firebase';

const firebaseConfig = {
	apiKey            : 'AIzaSyApxJ2JVye3rIb6p-7PAGdhwGvCU28wymI',
	authDomain        : 'shinystories-mobile.firebaseapp.com',
	databaseURL       : 'https://shinystories-mobile.firebaseio.com',
	projectId         : 'shinystories-mobile',
	storageBucket     : 'shinystories-mobile.appspot.com',
	messagingSenderId : '1043287455815',
	appId             : '1:1043287455815:web:cbb2e566e9802c77609962',
};

const fb = firebase.initializeApp(firebaseConfig);

export { fb as firebase };
