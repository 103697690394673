
import { firebase } from './firebase';
import 'firebase/auth';

const createNewUser = (username, password) => {
	const email = `${username}@shinystories.app`;
	return firebase.auth().createUserWithEmailAndPassword(email, password);
};

const signInUser = (username, password) => {
	const email = `${username}@shinystories.app`;
	return firebase.auth().signInWithEmailAndPassword(email, password);
};

const signOutUser = () => firebase.auth().signOut();

const checkUserAuth = (callback) => firebase.auth().onAuthStateChanged((user) => callback(user));

const getCurrentUser = () => firebase.auth().currentUser;

export {
	createNewUser,
	signInUser,
	signOutUser,
	checkUserAuth,
	getCurrentUser,
};