import { createRouter, createWebHistory } from 'vue-router';
import { checkUserAuth } from '../services/auth';

const routes = [
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	},
	{
		path: '/locked',
		name: 'Shiny Locked',
		component: () => import(/* webpackChunkName: "shiny-locked" */ '../views/ShinyLocked.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		meta: { publicOnly: true },
	},
	{
		path: '/',
		name: 'Games',
		component: () => import(/* webpackChunkName: "games" */ '../views/Games.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/file',
		name: 'File',
		component: () => import(/* webpackChunkName: "file" */ '../views/File.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/hunt',
		name: 'Hunt',
		component: () => import(/* webpackChunkName: "hunt" */ '../views/Hunt.vue'),
		meta: { requiresAuth: true },
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((route) => route.meta.requiresAuth)) {
		checkUserAuth((user) => {
			if (user) next();
			else next('/login');
		});
	} else if (to.matched.some((route) => route.meta.publicOnly)) {
		checkUserAuth((user) => {
			if (user) next('/');
			else next();
		});
	} else next();
});

export default router;
