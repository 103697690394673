<template>
	<div class="container-fluid mb-3 py-2 bg-light">
		<div class="row">
			<div class="col-8 col-sm-5 col-md-4 col-xl-3">
				<router-link to="/locked">Shiny Locked Pokémon</router-link>
			</div>
			<div class="col-4 text-right text-sm-left">
				<router-link to="/about">About</router-link>
			</div>
		</div>
	</div>
	<router-view />
</template>

<style lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap.scss';

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;

	.shiny-img {
		display: block;
		margin: 0 auto;
		max-width: 60%;
		height: auto;
	}
}
</style>
